import * as Sentry from '@sentry/gatsby';
import { isFunction } from 'lodash-es';
import './src/styles/global.css';

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};

export const onRouteUpdate = () => {
  const faviconEl = document.querySelector('link[rel="icon"]');
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  if (!mediaQuery?.addEventListener || isFunction(mediaQuery.addEventListener)) {
    return;
  }

  mediaQuery.addEventListener('change', themeChange);

  if (mediaQuery.matches) {
    faviconEl.setAttribute('href', '/favicon-dark.svg');
  } else {
    faviconEl.setAttribute('href', '/favicon.svg');
  }

  function themeChange(event) {
    console.log(event.matches ? 'dark' : 'light');
    if (event.matches) {
      faviconEl.setAttribute('href', '/favicon-dark.svg');
    } else {
      faviconEl.setAttribute('href', '/favicon.svg');
    }
  }
};

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://bc5a88c8f9424ab7a9a63d63d945a67d@o4506169737347072.ingest.us.sentry.io/4506276334010368',
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: ['localhost', /^https:\/\/api\.timewavehr\.com/],
      }),
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
